import './app.scss';
import Routes from "./routes";
import { ConfigProvider, App as AntApp } from 'antd';

const AntdTheme = {
    token: {
        borderRadius: 2,
    },
};

export default function App() {
    return (
        <ConfigProvider theme={AntdTheme}>
            <AntApp>
                <Routes />
            </AntApp>
        </ConfigProvider>
    );
}
