import { useRoutes, matchRoutes } from 'react-router-dom';
import Layout from '../layout';
import loadable from '../layout/loader/loadable';

const LaunchesPage = loadable(() => import('../pages/home'));

const elements = [
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: '/',
                element: <LaunchesPage />,
            },
        ],
    },
];

const routes = elements[0].children.map(({ path }) => ({ path }));
export const getCurrentPath = (path: string) => {
    const match = matchRoutes(routes, path);
    return match;
};

export default function Routes() {
    return useRoutes(elements);
}
