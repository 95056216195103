import { Outlet } from 'react-router-dom';
import logoAutraTech from '../assets/images/autra_tech.png';
import { Layout, theme } from 'antd';
const { Header, Content } = Layout;

export default function MyLayout() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ padding: 0, background: 'rgba(0,0,0,.9)' }}>
                <div className='logo-box'>
                    <img src={logoAutraTech} className='logo' />
                </div>
            </Header>
            <Content style={{ padding: 0, margin: 0, overflow: 'hidden' }} >
                <Outlet />
            </Content>
        </Layout>
    );
};


