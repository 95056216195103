import { Suspense, ComponentType, lazy } from 'react';

const loadable = (comp: () => Promise<{ default: ComponentType }>) => {
    const Component = lazy(comp);

    return (props: object) => (
        <Suspense fallback={null}>
            <Component {...props} />
        </Suspense>
    );
};

export default loadable;
